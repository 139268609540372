import { 
    USER_LOAD,
    USER_LOAD_SUCCESS,
    AUTH_NO_TOKEN,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    MY_POSTS_GET,
    MY_POSTS_GET_FAIL,
    MY_POSTS_GET_SUCCESS,
    USER_REGISTRATION_SUCCESS,
    USER_REGISTRATION_FAIL,

    CSRF_GET,
    CSRF_GET_FAIL,
    CSRF_GET_SUCCESS,
    CSRF_UPDATE,
    SESSION_GET,
    SESSION_GET_FAIL,
    SESSION_GET_LOGGED_OUT,
    SESSION_GET_SUCCESS
} from '../common/types';

const initialState = {
    isLoading: true,
    isGettingCsrf: true,
    isGettingSession: true,
    isGettingUser: true,
    isLoadingMyPosts: true,
    isAuthenticated: null,
    csrftoken: null,
};

export const auth_reducer = (state = initialState, action) => {
    switch(action.type) {
        case CSRF_GET:
            return {
                ...state,
                isGettingCsrf: true
            };
        case CSRF_GET_FAIL:
            return {
                ...state,
                isGettingCsrf: false,
                csrftoken: null
            }
        case CSRF_GET_SUCCESS:
            return {
                ...state,
                isGettingCsrf: false,
                csrftoken: action.payload.csrftoken
            }
        case CSRF_UPDATE:
            return {
                ...state,
                csrftoken: action.payload.csrftoken
            }
        case SESSION_GET:
            return {
                ...state,
                isGettingSession: true
            };
        case SESSION_GET_FAIL:
            return {
                ...state,
                isGettingSession: false,
                isAuthenticated: false,
                csrftoken: null
            };
        case SESSION_GET_LOGGED_OUT:
            return {
                ...state,
                isGettingSession: false,
                isAuthenticated: false,
            };
        case SESSION_GET_SUCCESS:
            return {
                ...state,
                isGettingSession: false,
                isAuthenticated: true,
            };
        case USER_LOAD:
            return {
                ...state,
                isLoading: true,
                isGettingUser: true
            };
        case USER_LOAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isGettingUser: false,
                isAuthenticated: true,
            };
        case LOGIN_SUCCESS:
        case USER_REGISTRATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                csrftoken: action.payload.csrftoken
            };
        case AUTH_NO_TOKEN:
        case LOGOUT_SUCCESS:
        case LOGOUT_FAIL:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                crsftoken: action.payload.csrftoken
            };
        case LOGIN_FAIL:
        case AUTH_ERROR:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                csrftoken: null
            };
        case MY_POSTS_GET:
            return {
                ...state,
                isLoadingMyPosts: true,
            };
        case MY_POSTS_GET_FAIL:
            return {
                ...state,
                isLoadingMyPosts: false
            };
        case MY_POSTS_GET_SUCCESS:
            return {
                ...state,
                isLoadingMyPosts: false,
            };
        default:
            return state;
    }
}
