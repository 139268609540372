import { app_reducer } from '../reducers/app';
import { auth_reducer } from '../auth/reducers';
import { combineReducers } from 'redux';
import { invitation_reducer } from '../reducers/invitations';
import { posts_reducer } from '../reducers/posts';
import { user_reducer } from '../reducers/user_reducer';

export default combineReducers({
	app: app_reducer,
    auth: auth_reducer,
    posts: posts_reducer,
    user: user_reducer,
    user_invites: invitation_reducer
})
