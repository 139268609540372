export const httpCodes = {
    '200': {
        'code': '200',
        'type': 'Success',
        'msg': 'Request OK'
    },
    '203': {
        'code': '203',
        'type': 'Success',
        'msg': 'Request OK, No Content Returned'
    },
    '400': {
        'code': '400',
        'type': 'Error',
        'msg': 'Bad Request'
    },
    '401': {
        'code': '401',
        'type': 'Error',
        'msg': 'Invalid Credentials'
    },
    '403': {
        'code': '403',
        'type': 'Error',
        'msg': 'Forbidden'
    },
    '404': {
        'code': '404',
        'type': 'Error',
        'msg': 'Invalid Resource'
    },
    '405': {
        'code': '405',
        'type': 'Error',
        'msg': 'Unallowed Request Method'
    },
    '500': {
        'code': '500',
        'type': 'Error',
        'msg': 'Internal Server Error'
    },
    '999': {
        'code': 'Unspecified',
        'type': 'Error',
        'msg': 'An unspecified error occured.'
    }
};

// The way errors are formatted can vary based on the form, the API call,
// and what went wrong. This is resulting in ugly duplicated code. I'll
// unify it here.
// This will return an object containing:
//      1. An object containing the HTTP error and explanation e.g. {"Error", "403", "Forbidden"}
//      2. A non_field_errors string OR array containing non-field-errors
//      3. An additional entry for every other field, presumably corresponding to form field errors
const non_field_error_candidates = ['msg', 'detail', 'non_field_errors'];

export const httpErrorHelper = (status, resp) => {
    const errors = {};
    const httpCode = httpCodes[status] || httpCodes['999'];
    var non_field_errors = [];
    errors.http = httpCode;
    errors.title = `${errors.http.code} ${errors.http.msg}`;

    for(const field in resp) {
        if(non_field_error_candidates.includes(field)) {
            if(Array.isArray(resp[field])) {
                non_field_errors = non_field_errors.concat(resp[field]);
            }
            else {
                non_field_errors.push(resp[field]);
            }
        }
        else {
            errors[field] = resp[field];
        }
    }

    if(non_field_errors.length === 1) {
        errors.non_field_errors = non_field_errors[0];
    }
    else if(non_field_errors.length > 1) {
        errors.non_field_errors = non_field_errors;
    }

    return errors;
};