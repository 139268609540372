import {
    Box,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Text
} from 'grommet';

const StandardFZCard = ({body, head=null, foot=null, ...props}) => {
    return (
    <Box
        direction='column'
        fill='horizontal'
        align='center'
        gap='medium'
        pad={{horizontal: 'medium'}}>

        <Card
            flex
            fill='horizontal'
            width={{max: 'large'}}
            background='background-front'
            elevation='large'
            border={{color: 'brand', size: 'small'}}>
        
            { head && (
            <CardHeader
                fill='horizontal'
                align='center'
                pad={{left: 'small', top: 'xxsmall', bottom: 'xxsmall'}}
                background='background-contrast'>

                {head}
            </CardHeader>
            )}
    
            <CardBody
                fill='horizontal'
                align='center'
                justify='center'
                pad='small'>
                
                {body}
            </CardBody>    

            { foot && (
                <CardFooter
                    fill='horizontal'
                    pad='small'
                    border={{color: 'brand', side: 'top'}}>

                    {foot}
                </CardFooter>
            )}
        </Card>
    </Box>
    );
};

const StandardCardHeaderText = (props) => {
    return (
    <Text
        size='medium'
        color='brand'
        weight='bold'>

        {props.children}
    </Text>
    );
};

const ErrorCard = ({title='Error', msg, icon=<i className='fas fa-bomb' />}) => {
    return (
    <Box
        a11yTitle={`${title} ${msg}`}
        direction='column'
        fill='horizontal'
        align='center'
        gap='medium'
        pad={{horizontal: 'medium'}}>

        <Card
            flex
            fill='horizontal'
            width={{max: 'large'}}
            background='status-critical-bg'
            elevation='large'
            border={{color: 'status-critical', size: 'small'}}>
        
            <CardHeader
                fill='horizontal'
                background='status-critical-bg'
                direction='row'
                align='start'
                border={{color: 'status-critical', size: 'small', side: 'bottom'}}
                pad={{horizontal: 'small', vertical: 'xxsmall'}}
                gap='none'>
                
                <Text color='status-critical-text' weight='bold'>{icon}  {title}</Text>
            </CardHeader>
    
            <CardBody
                fill='horizontal'
                align='center'
                justify='center'
                pad='small'>
               
                <Text color='status-critical-text'>{msg}</Text>
            </CardBody>
        </Card>
    </Box>
    );
};

export {
    StandardFZCard,
    StandardCardHeaderText,
    ErrorCard
};
