import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Layer,
    ResponsiveContext,
    Text
} from 'grommet';
import { FormClose } from 'grommet-icons';
import { useContext, useCallback, useEffect, useState } from 'react';

// A happy, green success notification
export const SuccessNotification = ({ show, setShow, msg, ...props}) => {
    return (
    <FZNotification
        show={show}
        setShow={setShow}
        msg={msg}
        bg='status-ok'
        icon={props.icon ? props.icon : <i className='fas fa-checkcircle' />} 
        {...props} />
    );
};

// A noticeable, yellow warning notification for non-critical problems or successful completion
// of destructive actions (e.g. deleting a post, unfriending someone)
export const WarningNotification = ({show, setShow, msg, ...props}) => {
    return (
    <FZNotification
        show={show}
        setShow={setShow}
        msg={msg}
        bg='status-warning'
        icon={props.icon ? props.icon : <i className='fas fa-exclamation-circle' />}
        {...props} />
    );
};

// An angry, red error notification to warn the user when something goes wrong
export const ErrorNotification = ({show, setShow, msg, ...props}) => {
    return (
    <FZNotification
        show={show}
        setShow={setShow}
        msg={msg}
        bg='status-critical'
        icon={props.icon ? props.icon : <i className='fas fa-bomb' />}
        {...props} />
    );
};

// A chill, purple notification for providing bite-sized pieces of information to the user.
export const InfoNotification = ({show, setShow, msg, ...props}) => {
    return (
    <FZNotification
        show={show}
        setShow={setShow}
        msg={msg}
        bg='brand'
        icon={props.icon ? props.icon : <i className='fas fa-info-circle' />}
        {...props} />
    );
};

const FZNotification = ({show, setShow, msg, bg, icon, ...props}) => {
    const onClose = () => setShow(false);
    const [color, setColor] = useState('status-unknown');

    useEffect(() => {
        if(props.timeout) {
            const timer = setTimeout(() => {
                setShow(false);
            }, Number.isInteger(props.timeout) ? props.timeout : 3000);
            return () => clearTimeout(timer);
        }
    });

    return (
        <>
        {show && (
        <Layer
            position='bottom'
            modal={false}
            margin={{'vertical': 'medium', 'horizontal': 'small'}}
            responsive={false}
            animation='slide'
            animate={true}
            onEsc={onClose}
            plain>
            <Box
                align='center'
                direction='row'
                gap='small'
                justify='between'
                round='medium'
                elevation='large'
                pad={{'vertical': 'xsmall', 'horizontal': 'small'}}
                background={bg}>

                <Box align='center' direction='row' gap='xsmall'>
                    {icon}
                    <Text>{msg}</Text>
                </Box>
                <Button icon={<FormClose />} onClick={onClose} plain />
            </Box>
        </Layer>
        )}
        </>
    );
};

export const StandardFZModal = ({body, show, setShow, ...props}) => {
    const size = useContext(ResponsiveContext);
    
    const handleModalHide = useCallback(e => {
        setShow(undefined);
        e.preventDefault();
    }, [setShow]);

    return (
    <>
    {show && (
        <Layer
            position='center'
            responsive={false}
            full={size === 'small' ? 'horizontal' : false}
            margin={size === 'small' ? 'small' : 'none'}
            animation='slide'
            onEsc={handleModalHide}
            onClickOutside={handleModalHide}>

            <Card
                flex
                fill='horizontal'
                width={{max: 'large'}}
                direction='column'
                round='xxsmall'
                background='background-back'
                border={{color: 'border', size: 'medium'}}
                elevation='xlarge'>
                
                {props.head && (
                    <CardHeader
                        background='brand'
                        align='center'
                        justify='center'
                        pad='xsmall'>

                        {props.head}
                    </CardHeader>
                )}
                
                <CardBody
                    direction='row'
                    align='center'
                    gap='medium'
                    pad='small'>

                    {body}
                </CardBody>

                <CardFooter
                    direction='row-responsive'
                    justify='end'
                    border={{color: 'border', side: 'top'}}
                    pad='small'>

                    <Button
                        secondary
                        color='brand'
                        label='Cancel'
                        onClick={handleModalHide} />

                    {props.actions}
                </CardFooter>
            </Card>
        </Layer>
    )}
    </>);
};
