import {
    LOGIN_SUCCESS,
    MY_POSTS_GET,
    MY_POSTS_GET_FAIL,
    MY_POSTS_GET_SUCCESS,
    POST_DELETE,
    POST_DELETE_FAIL,
    POST_DELETE_SUCCESS,
    POST_SEND,
    POST_SEND_FAIL,
    POST_SEND_SUCCESS,
    POST_UPDATE,
    POST_UPDATE_FAIL,
    POST_UPDATE_SUCCESS,
    USER_LOAD_SUCCESS,
} from '../common/types.js';

const initialState = {
    isConsistent: true,
    isDeletingPost: false,
    isGettingPost: false,
    isPosting: false,
    recent_posts: null
};

export const posts_reducer = (state = initialState, action) => {
    switch(action.type) {
        case MY_POSTS_GET:
            return {
                ...state,
                isGettingPost: true
            };
        case MY_POSTS_GET_FAIL: 
            return {
                ...state,
                isConsistent: false,
                isGettingPost: false,
            };
        case MY_POSTS_GET_SUCCESS:
        case LOGIN_SUCCESS:
        case USER_LOAD_SUCCESS:
            return {
                ...state,
                isConsistent: true,
                isGettingPost: false,
                recent_posts: action.payload.recent_posts
            }
        case POST_DELETE:
            return {
                ...state,
                isDeletingPost: true
            };
        case POST_DELETE_FAIL:
            return {
                ...state,
                isConsistent: false,
                isDeletingPost: false,
            };
        case POST_DELETE_SUCCESS:
            console.log(`Reducer removing index ${action.payload.index}`);
            return {
                ...state,
                isDeletingPost: true,
                recent_posts: [...state.recent_posts.slice(0, action.payload.index),
                                ...state.recent_posts.slice(action.payload.index + 1)]
            }
        case POST_SEND:
            return {
                ...state,
                isSendingPost: true
            };
        case POST_SEND_FAIL:
            return {
                ...state,
                isSendingPost: false
            };
        case POST_SEND_SUCCESS:
            return {
                ...state,
                isSendingPost: false,
                recent_posts: [action.payload.post, ...state.recent_posts]
            }
        case POST_UPDATE:
            return {
                ...state,
                isPosting: true
            };
        case POST_UPDATE_FAIL:
            return {
                ...state,
                isPosting: false
            };
        case POST_UPDATE_SUCCESS:
            return {
                ...state,
                isPosting: false,
                recent_posts: [...state.recent_posts.slice(0, action.payload.index),
                                action.payload.post,
                                ...state.recent_posts.slice(action.payload.index + 1)]
            };
        default:
            return state;
    }
};
