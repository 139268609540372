import {
    Box,
    Heading,
    Tab,
    Tabs,
    Text
} from 'grommet';
import QuickPost from './quickpost';
import AppBar from '../../common/components/app_bar';
import SelfPage from './self_page';
import InvitationManager from '../../invitations/components/invite_manager.js';
import {NotFound} from '../../common/components/errors';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {Link, Route, Switch, useRouteMatch} from 'react-router-dom';

export const Main = (props) => {
    const user = useSelector((state) => state.user);
    const [routerInfo, setRouterInfo] = useState(useRouteMatch());
    
    return (
    <>
    <AppBar />
    <Switch>
    <Route exact path='/'>
    <Box
        direction='column'
        align='center'
        gap='medium'
        background='background'>

        <Heading margin='small' size='small'>Welcome, {user.first_name}</Heading>
        
        <Box direction='column'
            fill='horizontal'
            align = 'center'
            gap = 'medium'
            pad = {{'horizontal': 'medium'}}>

            <QuickPost />
        
        </Box>
    </Box>
    </Route>
    <Route path='/home/'>
    <Box><Heading>Test test test</Heading></Box>
    </Route>
    <Route path='/me'>
        <SelfPage />
    </Route>
    <Route path='/invitations'>
        <InvitationManager />
    </Route>
    <Route path='*'>
        <NotFound />    
    </Route>
    </Switch>
    </>
    );
};
