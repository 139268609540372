import _ from 'lodash';
import axios from '../common/axios';
import { getCsrfHeaders } from './auth';
import { httpCodes, httpErrorHelper } from '../common/network/http';
import {
    AUTH_NO_TOKEN,
    GETTING_POSTS,
    GET_POSTS_SUCCESS,
    GET_POSTS_FAIL,
    MY_POSTS_GET,
    MY_POSTS_GET_FAIL,
    MY_POSTS_GET_SUCCESS,
    POST_DELETE,
    POST_DELETE_FAIL,
    POST_DELETE_SUCCESS,
    POST_SEND,
    POST_SEND_FAIL,
    POST_SEND_SUCCESS,
    POST_UPDATE,
    POST_UPDATE_FAIL,
    POST_UPDATE_SUCCESS
} from '../common/types';

export const getSelfPosts = () => {
    return async(dispatch, getState) => {
        const headers = getCsrfHeaders(getState);
        if(_.isEmpty(headers)) {
            dispatch({ type: AUTH_NO_TOKEN });
        }
        else {
            dispatch({ type: MY_POSTS_GET });
            try {
                const res = await axios.get('/base/posts/get/mine/', headers);
                if(res.success) {
                    dispatch({
                        type: MY_POSTS_GET_SUCCESS,
                        payload: { recentPosts: res.data.posts }
                    });
                    return { success: true };
                }
                else {
                    dispatch({ type: MY_POSTS_GET_FAIL });
                    return { success: false };
                }
            }
            catch(e) {
                dispatch({ type: MY_POSTS_GET_FAIL });
                return { success: false };
            }
        }
    };
};

export const getPosts = (userId) => {
    return async(dispatch, getState) => {
        const headers = getCsrfHeaders(getState);
        if(_.isEmpty(headers)) {
            dispatch({ type: AUTH_NO_TOKEN });
        }
        else {
            dispatch({ type: MY_POSTS_GET });
            try {
                const url = `/base/posts/get/${userId}/`;
                const res = await axios.get(url, headers);
                if(res.success) {
                    dispatch({
                        type: MY_POSTS_GET_SUCCESS,
                        payload: { recentPosts: res.data.posts }
                    });
                    return {
                        success: true,
                        recentPosts: res.data.posts
                    };
                }
            }
            catch(e) {
                dispatch({ type: MY_POSTS_GET_FAIL });
                return { success: false };
            }
        }
    }
};

export const quickPost = (content) => {
    return async (dispatch, getState) => {
        const headers = getCsrfHeaders(getState);
        if(_.isEmpty(headers)) {
            dispatch({
                type: AUTH_NO_TOKEN
            });
        }
        else {
            dispatch({ type: POST_SEND });
            try {
                const payload = JSON.stringify({content});
                const res = await axios.post('/base/posts/quick/', payload, headers);
                dispatch({
                    type: POST_SEND_SUCCESS,
                    payload: { post: res.data.post }
                });
                return {
                    success: true,
                    msg: 'Post created',
                    post: res.data.post
                };
            }
            catch(e) {
                dispatch({ type: POST_SEND_FAIL });
                if(e.response) {
                    let httpCode = httpCodes[e.response.status] || httpCodes['default'];
                    let http_msg = httpCode.type + ' ' + httpCode.code + ': ' + httpCode.msg;
                    let err_str = e.response.msg
                        ? e.response.msg
                        : http_msg;
                    return {
                        success: false,
                        msg: err_str
                    };
                }
            }
        }
    };
};

export const editPost = (arrayIdx, id, content) => {
    return async(dispatch, getState) => {
        const headers = getCsrfHeaders(getState);
        if(_.isEmpty(headers)) {
            dispatch({ type: AUTH_NO_TOKEN });
            return ({ success: false });
        }
        dispatch({ type: POST_UPDATE });
        try {
            const payload = JSON.stringify({ content });
            const res = await axios.patch(`/base/posts/edit/${id}/`, payload, headers);
            if(res.data.success) {
                dispatch({
                    type: POST_UPDATE_SUCCESS,
                    payload: { 
                        index: arrayIdx,
                        post: res.data.post
                    }
                });
                return { success: true };
            }
            else {
                dispatch({ type: POST_UPDATE_FAIL });
                return {
                    success: false,
                    errors: httpErrorHelper(res.status, res)
                };
            }
        }
        catch(e) {
            dispatch({type: POST_UPDATE_FAIL });
            return {
                success: false,
                errors: httpErrorHelper(e.response.status, e.response.data)
            }
        }
    };
};

export const deletePost = (arrayIdx, id) => {
    return async(dispatch, getState) => {
        const headers = getCsrfHeaders(getState);
        if(_.isEmpty(headers)) {
            dispatch({ type: AUTH_NO_TOKEN });
            return {success: false };
        }
        else {
            dispatch({ type: POST_DELETE });
            try {
                //const res = await axios.delete(`/base/posts/delete/${id}/`, headers);
                const res={data: {success: true}};
                console.log(`Test delete of post ${id} (local index ${arrayIdx})`);
                if(res.data.success) {
                    dispatch({
                        type: POST_DELETE_SUCCESS,
                        payload: { index: arrayIdx }
                    });
                    return {
                        success: true,
                        idx: arrayIdx,
                        id: id
                    };
                }
                else {
                    dispatch({ type: POST_DELETE_FAIL })
                    return { success: false };
                }
            }
            catch(e) {
                dispatch({ type: POST_DELETE_FAIL });
                return { success: false };
            }
        }
    };
};