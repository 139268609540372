import {
    Button,
    Box,
    Card,
    CardBody,
    CardHeader,
    Form,
    FormField,
    Text,
    TextArea
} from 'grommet';
import { Contact } from 'grommet-icons';
import { ErrorField } from '../../common/components/form_fields';
import { SuccessNotification } from '../../common/components/notifications';
import { quickPost } from '../../actions/posts';
import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const QuickPost = () => {
    const [isPosting, setIsPosting] = useState(false);
    const [postContent, setPostContent] = useState({'posttext': ''});
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [notification, setNotification] = useState(false);

    const setLimitedValue = useCallback(
        text => {
            setPostContent({'posttext': text.slice(0, 256)});
        },
        [setPostContent]
    );

    const dispatch = useDispatch();
    const handleSubmission = (e) => {
        e.preventDefault();
        setIsPosting(true);
        dispatch(quickPost(postContent.posttext), [dispatch]).then((res) => {
            setShowErrorMessage(!res.success);
            if(res.success) {
                setPostContent({'posttext': ''});
                setNotification('Post created successfully!');
                setShowNotification(true);
            }
            else {
                setErrorMessage(res.msg);
            }
        setIsPosting(false);
        });
    };

    return (
        <Card
            flex
            fill='horizontal'
            width={{'max': 'large'}}
            background='background-front'
            elevation='large'
            border={{color: 'brand', size: 'small'}}>
            
            <CardHeader
                fill='horizontal'
                align='center'
                pad={{'left': 'small', 'top': 'xxsmall', 'bottom': 'xxsmall'}}
                background='background-contrast'>
                <Text
                    size='medium'
                    color='brand'
                    weight='bold'>
                    Quick Post
                </Text>
            </CardHeader>
            <CardBody
                fill='horizontal'
                pad='small'
                align='center'
                justify='center'
                gap='small'>
               
                <ErrorField
                    msg={errorMessage}
                    show={showErrorMessage}
                    setShow={setShowErrorMessage} />

                <Form
                    value={postContent}
                    onReset={() => {}}
                    style={{'width': '100%'}}
                    onSubmit={handleSubmission}>
                <FormField
                    a11yTitle='Quick post text area'
                    name='posttext'
                    rows='3'
                    info={postContent.posttext.length + '/256'}
                    style={{'width': '100%'}}
                    resize={false}
                    onChange={(e) => setLimitedValue(e.target.value)}
                    as={TextArea} />
                    
                    <Box align='center'> 
                        <Button
                            type='submit'
                            a11yTitle='Quick post submit button'
                            icon={isPosting
                                ? <i className='fas fa-spin fa-spinner' />
                                : <i className='far fa-comment-alt' />}
                            label='Say Something'
                            active={false}
                            size='medium'
                            plain={false}
                            disabled={(postContent.posttext.length === 0 || isPosting)
                                ? true
                                : false}
                            primary />
                    </Box>
                </Form>
            </CardBody>
        <SuccessNotification
            show={showNotification}
            setShow={setShowNotification}
            msg={notification} /> 
        </Card>

    );
};

export default QuickPost;
