import axios from 'axios';
import store from './store';
import { CSRF_UPDATE } from './types';

const fzAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
    withCredentials: true,
    headers: {
        'Content-type': 'application/json'
    }
});

const fzAxiosInterceptors = (store) => {
	fzAxiosInstance.interceptors.request.use((cfg) => {
		console.log('Axios!');
		return cfg;
	});


	fzAxiosInstance.interceptors.response.use((resp) => {
		console.log(`RESP: ${resp.data}`);
		if(resp.data['X-CSRFToken']) {
			console.log('Updating CSRF token.');
			//store.dispatch({
			//	type: CSRF_UPDATE,
			//	payload: { csrftoken: resp.data['X-CSRFToken'] }
			//});
		}
		else {
			console.log('Not updating CSRF token.');
		}
		return resp;
	});
};

export default fzAxiosInstance;
export { fzAxiosInterceptors };