import {
	Anchor,
	Box,
	Heading,
	Image,
	Stack,
	Text
} from 'grommet';
import { Alert } from 'grommet-icons';
import { Link } from 'react-router-dom';

export const DevEnvironmentAppBarLogo = () => {
	return (
	<Stack anchor='center'>
		<Box 
			height='xxsmall'
       		width='xxsmall'>
        	
        	<Image 
           		a11yTitle='Link to Home via FZ logo'
           		fit='cover'
           		src='/assets/fz_logo_invert.png' />
   		</Box>
   		<Box
    		background='status-critical'
    		pad='xsmall'
    		round>
    		
    		<Text size='small'>DEV</Text>		
    	</Box>
	</Stack>
	);
};

export const DevEnvironmentWarning = () => {
	return (
	<Box
		pad='medium'
    	align='center'
    	direction='column'
    	gap='medium'>
    
    	<Heading textAlign='center'><Alert color='status-critical' /> Dev Environment! <Alert color='status-critical' /></Heading>
        	<Text textAlign='center'>This is a development environment. It is not intended for online use. Do not deploy this build directly. Push it and merge with the test branch when it's ready for prime time.</Text>
    	    <Text size='large' textAlign='center'><Anchor href="https://www.fucczucc.com/">Go to the main site</Anchor></Text>
	</Box>
	);
};

const TestEnvironmentWarning = () => {
	return (
	<Box
		pad='medium'
    	align='center'
    	direction='column'
    	gap='medium'>
    
    	<Heading textAlign='center'><Alert color='border' />Test Environment!<Alert color='border' /></Heading>
        	<Text textAlign='center'>This is a development environment. It is not full-featured and does not operate on the main database. You will notice a longer-than-usual delay in the amount of time it takes for user actions to become visible once made. It is recommended that you do not work in this environment unless you've talked to one of the creators about it.</Text>
    	    <Text size='large' textAlign='center'><Anchor href="https://www.fucczucc.com/">Go to the main site</Anchor></Text>
	</Box>
	);
};