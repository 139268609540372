import {
    Box,
    Card,
    CardBody,
    CardHeader,
    Heading,
    Text,
    Tip
} from 'grommet';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const buildLocationString = (profile) => {
    const cityStr = (profile.city + ', ') || '';
    const stateStr = (profile.state + ', ') || '';
    const countryStr = profile.country || '';

    return cityStr + stateStr + countryStr;
};

const SelfProfile = (props) => {
    const user = useSelector((state) => state.user);
    const [isEditing, setIsEditing] = useState(false);

    return (
    <Box
        direction='column'
        align='center'
        gap='medium'
        fill='horizontal'>
        
        <Text weight='bold'>{user.profile.bio}</Text>
       
        <SelfProfileCard name='Your' user={user} />
    </Box>
    );
};

const SelfProfileCard = ({name, user}) => {
    return (
    <Box
        direction='column'
        fill='horizontal'
        align='center'
        gap='medium'
        pad={{horizontal: 'medium'}}>
        
        <Card
            flex
            fill='horizontal'
            width={{'max': 'large'}}
            background='background-front'
            elevation='large'
            border={{color: 'brand', size: 'small'}}>

            <CardHeader
                fill='horizontal'
                align='center'
                pad={{left: 'small', top: 'xxsmall', bottom: 'xxsmall'}}
                background='background-contrast'>
        
                <Text
                    size='medium'
                    color='brand'
                    weight='bold'>
                    {name} Information
                </Text>
            </CardHeader>

            <CardBody>
                <Box
                    direction='column'
                    fill='horizontal'
                    justify='center'
                    border={{side: 'bottom', color: 'brand'}}
                    pad={{horizontal: 'small'}}>

                    <AttributeBox
                        attr='Location'
                        value={buildLocationString(user.profile)} />
                    <AttributeBox attr='Z-Code' value={user.profile.code} />
                </Box>
                <Box
                    wrap
                    direction='row'
                    fill='horizontal'
                    align='center'
                    justify='center'>
                    
                    <BadgeBox label={user.profile.generation} background='text-strong' color='background-front' info={'Generation ' + user.profile.generation} />

                    {user.profile.is_old_100
                        ? <BadgeBox label='100' background='status-ok' color='status-ok' info='Old 100' />
                        : null
                    }
                    {user.profile.is_custodian
                        ? <BadgeBox label='C' background='status-warning' color='status-critical' info='Custodian' />
                        : null
                    }
                </Box>
            </CardBody>
        </Card>
    </Box>
    );
};

const AttributeBox = ({attr, value}) => {
    return (
    <>
    <Text>{attr}:</Text>
    <Text weight='bold' margin={{left: 'small', bottom: 'small'}}>
        {value}
    </Text>
    </>
    );
};

const BadgeBox = ({label, color, background, info}) => (
    <Tip
        plain
        dropProps={{ align: { 'top': 'bottom' } }}
        content={
            <Box
                background='brand'
                round='medium'
                elevation='medium'
                animation='fadeIn'
                align='center'
                justify='center'
                margin={{left: 'medium', top: 'xsmall', bottom: 'medium', right: 'medium'}}
                pad={{ horizontal: 'small', vertical: 'xsmall'}}>
           
                {info}
            </Box>
        }>
        
    <Box
        round='xxsmall'
        width='xxsmall'
        height='xxsmall'
        margin='small'
        align='center'
        justify='center'
        color={color}
        background={{color: background, opacity: 'medium'}}
        border={{color: background, size: 'small'}}>
        
        <Text weight='bold'>{label}</Text>

    </Box>
    </Tip>
);

export {SelfProfile};
