import {
    AUTH_NO_TOKEN,
    USER_LOAD,
    USER_LOAD_SUCCESS,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    USER_REGISTRATION_SUCCESS,
    USER_REGISTRATION_FAIL,
    UPDATE_INVITE_DATA_SUCCESS,
    UPDATE_INVITE_DATA_FAIL
} from '../common/types';

const initialState = {
    isLoading: true,
    user: null
};

export const user_reducer = (state = initialState, action) => {
    switch(action.type) {
        case USER_LOAD:
            return {
                ...state,
            };
        case LOGIN_SUCCESS:
        case USER_LOAD_SUCCESS:
        case USER_REGISTRATION_SUCCESS:
            return {
                ...state,
                ...action.payload.user
            };
        case UPDATE_INVITE_DATA_SUCCESS:
            return {
                ...state,
                invitation_manager: action.payload.manager,
                fzinvitation_set: [action.payload.invitation, ...state.fzinvitation_set]
            };
        case AUTH_NO_TOKEN:
        case LOGOUT_SUCCESS:
        case LOGOUT_FAIL:
        case USER_REGISTRATION_FAIL:
        case UPDATE_INVITE_DATA_FAIL:
            return {
                user: null
            };
        default:
            return state;
    }
};
