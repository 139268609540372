import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Form,
    FormField,
    Text,
    TextInput,
} from 'grommet';
import {ErrorCard} from '../../common/components/base_card';
import React, {useState } from 'react';
import { Redirect } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {login} from '../../actions/auth';

const LoginForm = () => {
    const [value, setValue] = useState({email: '', password: ''});
    const [hasErrors, setHasErrors] = useState(false);
    const [errors, setErrors] = useState({});
    const authSuccess = useSelector((state) => state.auth.isAuthenticated);

    const dispatch = useDispatch();
    const handleLogin = (e) => {
        e.preventDefault();
        dispatch(login(value.email, value.password), [dispatch]).then((res) => {
            if(!res.success) {
                setHasErrors(true);
                console.log(res.errors);
                setErrors(res.errors);
                console.log(errors);
            }
        });
    };
   
    if(authSuccess) {
        return <Redirect to='/' />
    } 
    return (
    <Box
        align='center'
        margin='large'>
    
        <Card
            width='medium'
            alignContent='start'
            border={{'color': 'brand', 'size': 'medium'}}
            overflow='visible'
            elevation='xlarge'>

            <CardHeader
                align='center'
                justify='center'
                background='brand'>
                <Text
                margin='medium'
                fill='horizontal'
                size='xlarge'
                textAlign='center'
                weight='bold'
                color='text-strong'>
                    fz Dev Login
                </Text>
            </CardHeader>
            
            <CardBody margin='medium'
                overflow='auto'
                gap='medium'>
                { hasErrors && (
                    <ErrorCard 
                        title={`${errors.title}`}
                        msg={errors.non_field_errors} />
                )}
                <Form
                    value={value}
                    errors={errors}
                    onChange={newValue => setValue(newValue)}
                    onReset={() => {}}
                    onSubmit={handleLogin}>
                    <FormField name='email'
                        htmlfor='emailinput-id'
                        label = 'Email Address'
                        margin={{'bottom': 'medium'}}
                        required={true}>
                        
                        <TextInput 
                            id='emailinput-id'
                            name='email' 
                            placeholder='you@domain.com'
                            value={value.email} />
                    </FormField>

                    <FormField name='password'
                        htmlfor='passwordinput-id'
                        label='Password'
                        margin={{'bottom': 'medium'}}
                        required={true}>
                        <TextInput
                            id='passwordinput-id'
                            name='password'
                            type='password'
                            placeholder='Super Secret'
                            value={value.password} />
                    </FormField>
                    
                    <Box align='center'
                        margin='small'>
                    <Button
                        type='submit'
                        label='Log In'
                        active={false}
                        size='medium'
                        plain={false}
                        primary />
                    </Box>
                </Form>
            </CardBody>
        </Card>
    </Box>
    );
};

export default LoginForm
