// General app statuses
export const APP_LOADING = 'APP_LOADING';
export const APP_LOAD_SUCCESS = 'APP_LOAD_SUCCESS';
export const APP_LOAD_FAIL = 'APP_LOAD_FAIL';

// User- and auth-related
export const USER_LOAD = 'USER_LOAD';
export const USER_LOAD_FAIL = 'USER_LOAD_FAIL';
export const USER_LOAD_SUCCESS = 'USER_LOAD_SUCCESS';
export const AUTH_NO_TOKEN = 'AUTH_NO_TOKEN';
export const AUTH_ERROR = 'AUTH_ERROR';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

// New CSRF auth-related
export const CSRF_GET = 'CSRF_GET';
export const CSRF_GET_FAIL = 'CSRF_GET_FAIL';
export const CSRF_GET_SUCCESS = 'CSRF_GET_SUCCESS';
export const CSRF_UPDATE = 'CSRF_UPDATE';
export const SESSION_GET = 'GETTING_SESSION';						// Are we loading a session?
export const SESSION_GET_FAIL = 'SESSION_GET_FAIL';					// Attempt to obtain a session failed
export const SESSION_GET_LOGGED_OUT = 'SESSION_GET_LOGGED_OUT';		// Attempt to obtain a session succeeded, but the user has no valid session
export const SESSION_GET_SUCCESS = 'SESSION_GET_SUCCESS';			// Attempt to obtain a session succeeded, and ther user's session was returned

// Post-related
export const MY_POSTS_GET = 'MY_POSTS_GET';
export const MY_POSTS_GET_FAIL = 'MY_POSTS_GET_FAIL';
export const MY_POSTS_GET_SUCCESS = 'MY_POSTS_GET_SUCCESS';
export const POST_DELETE = 'POST_DELETE';
export const POST_DELETE_FAIL = 'POST_DELETE_FAIL';
export const POST_DELETE_SUCCESS = 'POST_DELETE_SUCCESS';
export const GETTING_POSTS = 'GETTING_POSTS';
export const GET_POSTS_SUCCESS = 'GET_POSTS_SUCCESS';
export const GET_POSTS_FAIL = 'GET_POSTS_FAIL';
export const POST_SEND = 'POST_SEND';
export const POST_SEND_FAIL = 'POST_SEND_FAIL';
export const POST_SEND_SUCCESS = 'POST_SEND_SUCCESS';
export const POST_UPDATE = 'POST_UPDATE';
export const POST_UPDATE_FAIL = 'POST_UPDATE_FAIL';
export const POST_UPDATE_SUCCESS = 'POST_UPDATE_SUCCESS';

// Invitation-related
export const UPDATING_INVITE_DATA = 'UPDATING_INVITE_DATA'; 
export const UPDATE_INVITE_DATA_SUCCESS = 'UPDATE_INVITE_DATA_SUCCESS'; 
export const UPDATE_INVITE_DATA_FAIL = 'UPDATE_INVITE_DATA_FAIL'; 
export const UPDATING_PENDING_INVITES_SUCCESS = 'UPDATING_PENDING_INVITES_SUCCESS';
export const UPDATING_PENDING_INVITES_FAIL = 'UPDATING_PENDING_INVITES_FAIL';
export const UPDATING_INVITE_MANAGER_SUCCESS = 'UPDATING_INVITE_MANAGER_SUCCESS';
export const UPDATING_INVITE_MANAGER_FAIL = 'UPDATING_INVITE_MANAGER_FAIL';
export const SENDING_INVITATION = 'SENDING_INVITATION';
export const INVITATION_SUCCESS = 'INVITATION_SUCCESS';
export const INVITATION_FAIL = 'INVITATION_FAIL';

// Registration-related
export const RETRIEVING_INVITATION = 'RETRIEVING_INVITATION';
export const INVITATION_RETRIEVE_SUCCESS = 'INVITATION_RETRIEVE_SUCCESS';
export const INVITATION_RETRIEVE_FAIL = 'INVITATION_RETRIEVE_FAIL';
export const REGISTERING_USER = 'REGISTERING_USER';
export const USER_REGISTRATION_SUCCESS = 'USER_REGISTRATION_SUCCESS';
export const USER_REGISTRATION_FAIL = 'USER_REGISTRATION_FAIL';
