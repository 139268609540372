import { useEffect } from 'react';
import { 
    Anchor,
    Box,
    Heading,
    Grommet,
    Text 
} from 'grommet' ;
import { Alert } from 'grommet-icons';
import { Redirect, Route, Switch } from 'react-router-dom';
import store from './common/store';
import { Main } from './base/components/main';
import { loadApp } from './actions/app';
import LoginForm from './auth/components/login.js';
import InvitationResponse from './invitations/components/invite_response';
import AuthenticatedRoute from './common/network/authenticated_route';
import { fz_theme } from './common/theme.js';
import { fzAxiosInterceptors } from './common/axios';
import { DevEnvironmentWarning } from './common/components/env_specific';

function App() {
  useEffect(() => {
      console.log(process.env.REACT_APP_API_BASE);
      //fzAxiosInterceptors(store);
      store.dispatch(loadApp());
  });

  return (
    <Grommet full theme={fz_theme} style={{ minHeight: '-webkit-fill-available' }} background='background'>
            <Switch>
            <Route path='/register' children={InvitationResponse} />
            <Route exact path='/login/'>
        <Box fill 
            direction='row-responsive'
            align='center'
            justify='center'
            gap='medium'
            background='background'>
            {process.env.REACT_APP_FZ_ENV === "FZDEV" && <DevEnvironmentWarning />}
            <Box width={{'min': '50%'}}>
                <LoginForm />
            </Box>
            </Box>
            </Route>
            <AuthenticatedRoute path='/' component={ Main } />
        </Switch>
    </Grommet>
  );
}

export default App;
